@import url('https://fonts.googleapis.com/css2?family=Gentium+Plus:ital,wght@0,700;1,400;1,700&display=swap');
html{
  background: white;
  overflow-x: hidden;

}

#root {
  font-family: 'Gentium Plus', serif;
  background: white;
}
